<template>
     <el-form @submit.prevent="submitForm" :model="form" :rules="rules" ref="userForm" class="user-input-form" novalidate>
       <p>All fields are required. Please enter accurate information for the best results.</p>
       <el-form-item label="Name" prop="name">
         <el-input v-model="form.name">
           <template #append>
             <el-button @click="generateRandomName">Random</el-button>
           </template>
         </el-input>
       </el-form-item>
       <el-form-item label="Gender" prop="gender">
         <el-radio-group v-model="form.gender">
           <el-radio label="male">Male</el-radio>
           <el-radio label="female">Female</el-radio>
         </el-radio-group>
       </el-form-item>
       <el-form-item label="Height (cm)" prop="height">
         <el-input-number v-model.number="form.height" :min="0" :max="300" :precision="1" :step="0.1"></el-input-number>
       </el-form-item>
       <el-form-item label="Weight (kg)" prop="weight">
         <el-input-number v-model.number="form.weight" :min="0" :max="400" :precision="1" :step="0.1"></el-input-number>
       </el-form-item>
       <el-form-item>
         <el-button type="primary" native-type="submit" :loading="loading">Add User</el-button>
       </el-form-item>
     </el-form>
   </template>
   
   <script setup>
   import { reactive, ref, onMounted } from 'vue'
   import { ElMessage } from 'element-plus'
   
   const emit = defineEmits(['update-data'])
   
   const form = reactive({
     name: '',
     gender: 'male',
     height: 170,
     weight: 70
   })
   
   const rules = {
     name: [
       { required: true, message: 'Please enter your name', trigger: 'blur' }
     ],
     gender: [
       { required: true, message: 'Please select your gender', trigger: 'change' }
     ],
     height: [
       { required: true, message: 'Please enter your height', trigger: 'blur' },
       { type: 'number', min: 0, max: 300, message: 'Height must be between 0 and 300 cm', trigger: 'blur' }
     ],
     weight: [
       { required: true, message: 'Please enter your weight', trigger: 'blur' },
       { type: 'number', min: 0, max: 400, message: 'Weight must be between 0 and 400 kg', trigger: 'blur' }
     ]
   }
   
   const userForm = ref(null)
   const loading = ref(false)
   
   const maleNames = ['John', 'Michael', 'David', 'James', 'Robert', 'William', 'Richard', 'Thomas', 'Charles', 'Joseph']
   const femaleNames = ['Mary', 'Patricia', 'Jennifer', 'Linda', 'Elizabeth', 'Barbara', 'Margaret', 'Susan', 'Dorothy', 'Lisa']
   
   const generateRandomName = () => {
     const names = form.gender === 'male' ? maleNames : femaleNames
     form.name = names[Math.floor(Math.random() * names.length)]
   }
   
   const submitForm = async () => {
     if (!userForm.value) return
   
     loading.value = true
     try {
       await userForm.value.validate()
       emit('update-data', {
         id: Date.now(),
         ...form
       })
       ElMessage.success('User data added successfully!')
       userForm.value.resetFields()
       form.gender = 'male' // Reset gender to default after submission
       generateRandomName() // Generate a new random name
     } catch (error) {
       ElMessage.error('Please fill in all fields correctly.')
     } finally {
       loading.value = false
     }
   }
   
   onMounted(() => {
     generateRandomName() // Generate a random name when the component is mounted
   })
   </script>
   
   <style scoped>
   .user-input-form {
     background-color: #fff;
     padding: 20px;
     border-radius: 8px;
     box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   }
   </style>
   