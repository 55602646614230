<template>
     <el-row :gutter="20">
          <el-col :span="24" v-for="data in userData" :key="data.id">
               <el-card class="body-data-card">
                    <template #header>
                         <div class="card-header">
                              <h3>{{ data.name }}</h3>
                         </div>
                    </template>
                    <p><strong>Gender:</strong> {{ capitalizeFirstLetter(data.gender) }}</p>
                    <p><strong>BMI:</strong> {{ calculateBMI(data) }}</p>
                    <p><strong>Ideal Weight Range:</strong> {{ calculateIdealWeight(data) }}</p>
               </el-card>
          </el-col>
     </el-row>
</template>

<script setup>
import { onMounted, watch } from 'vue'

const props = defineProps(['userData'])

const calculateBMI = (data) => {
     const bmi = data.weight / ((data.height / 100) ** 2)
     return bmi.toFixed(2)
}

const calculateIdealWeight = (data) => {
     const minWeight = 18.5 * ((data.height / 100) ** 2)
     const maxWeight = 24.9 * ((data.height / 100) ** 2)
     return `${minWeight.toFixed(1)}kg - ${maxWeight.toFixed(1)}kg`
}

const capitalizeFirstLetter = (string) => {
     return string.charAt(0).toUpperCase() + string.slice(1)
}

const updateStructuredData = () => {
     const script = document.querySelector('#structured-data')
     if (script) {
          document.head.removeChild(script)
     }

     const newScript = document.createElement('script')
     newScript.setAttribute('type', 'application/ld+json')
     newScript.setAttribute('id', 'structured-data')

     const structuredData = props.userData.map(data => ({
          "@context": "https://schema.org",
          "@type": "Person",
          "name": data.name,
          "gender": data.gender,
          "height": {
               "@type": "QuantitativeValue",
               "value": data.height,
               "unitCode": "CMT"
          },
          "weight": {
               "@type": "QuantitativeValue",
               "value": data.weight,
               "unitCode": "KGM"
          }
     }))

     newScript.textContent = JSON.stringify(structuredData)
     document.head.appendChild(newScript)
}

onMounted(updateStructuredData)
watch(() => props.userData, updateStructuredData, { deep: true })
</script>

<style scoped>
.body-data-card {
     margin-bottom: 20px;
}

.card-header {
     display: flex;
     justify-content: space-between;
     align-items: center;
}
</style>