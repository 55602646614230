<template>
     <div class="comparison">
          <p>This table provides a detailed comparison of all users' data. You can delete a user by clicking the
               'Delete' button.</p>
          <el-table :data="userData" style="width: 100%">
               <el-table-column prop="name" label="Name"></el-table-column>
               <el-table-column prop="gender" label="Gender">
                    <template #default="scope">
                         {{ capitalizeFirstLetter(scope.row.gender) }}
                    </template>
               </el-table-column>
               <el-table-column prop="height" label="Height (cm)"></el-table-column>
               <el-table-column prop="weight" label="Weight (kg)"></el-table-column>
               <el-table-column label="BMI">
                    <template #default="scope">
                         {{ calculateBMI(scope.row) }}
                    </template>
               </el-table-column>
               <el-table-column label="Actions">
                    <template #default="scope">
                         <el-button @click="deleteUser(scope.row.id)" type="danger" size="small">Delete</el-button>
                    </template>
               </el-table-column>
          </el-table>
     </div>
</template>

<script setup>
const props = defineProps(['userData'])
const emit = defineEmits(['delete-user'])

const calculateBMI = (data) => {
     const bmi = data.weight / ((data.height / 100) ** 2)
     return bmi.toFixed(2)
}

const capitalizeFirstLetter = (string) => {
     return string.charAt(0).toUpperCase() + string.slice(1)
}

const deleteUser = (userId) => {
     emit('delete-user', userId)
}
</script>

<style scoped>
.comparison {
     overflow-x: auto;
}
</style>