<template>
  <div class="container">
    <header>
      <h1>Body Shape Comparison</h1>
      <p>Ever wondered how your body compares to others? Here, you can compare different body shapes and learn about BMI (Body Mass Index) and ideal weight ranges.</p>
    </header>
    <main>
      <el-row :gutter="20">
        <el-col :xs="24" :sm="24" :md="8">
          <section class="input-section">
            <h2>Add User Data</h2>
            <p>Enter your details below to see how your body shape compares to others.</p>
            <UserInput @update-data="addUserData" />
          </section>
        </el-col>
        <el-col :xs="24" :sm="24" :md="16">
          <section v-if="userData.length > 0">
            <h2>Body Shape Visualization</h2>
            <p>This visual representation helps you compare different body shapes at a glance.</p>
            <BodyVisualization3D :userData="userData" />
          </section>
          <section v-if="userData.length > 0">
            <h2>Body Data Analysis</h2>
            <p>See calculated BMI and ideal weight ranges for each user.</p>
            <BodyData :userData="userData" />
          </section>
          <section v-if="userData.length > 0">
            <h2>Detailed Comparison</h2>
            <p>Compare all user data in a table format. You can delete users from this table.</p>
            <Comparison :userData="userData" @delete-user="deleteUser" />
          </section>
          <el-empty v-if="userData.length === 0"
            description="No data available. Please add user data to see comparisons."></el-empty>
        </el-col>
      </el-row>
    </main>
    <footer>
      <p>&copy; 2024 Body Shape Comparison Tool. All rights reserved.</p>
    </footer>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import UserInput from './components/UserInput.vue'
import BodyVisualization3D from './components/BodyVisualization3D.vue'
import BodyData from './components/BodyData.vue'
import Comparison from './components/Comparison.vue'

const userData = ref([])

const addUserData = (newData) => {
  userData.value.push(newData)
}

const deleteUser = (userId) => {
  userData.value = userData.value.filter(user => user.id !== userId)
}
</script>

<style scoped>
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

header,
footer {
  text-align: center;
  margin-bottom: 20px;
}

section {
  margin-bottom: 30px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-section {
  position: sticky;
  top: 20px;
}

h1,
h2 {
  color: #333;
  margin-bottom: 10px;
}

p {
  color: #666;
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .input-section {
    position: static;
  }
}
</style>
