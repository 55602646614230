<template>
     <div ref="container" class="visualization-container"></div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch } from 'vue';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

const props = defineProps({
     userData: {
          type: Array,
          required: true
     }
});

const container = ref(null);
let scene, camera, renderer, controls;
let humanoids = [];

const createHumanoid = (user) => {
     const group = new THREE.Group();

     // 计算比例因子
     const heightScale = user.height / 170; // 假设 170cm 为标准身高
     const weightScale = Math.pow(user.weight / 70, 1 / 3); // 假设 70kg 为标准体重，使用立方根来调整

     // Body
     const bodyGeometry = new THREE.BoxGeometry(weightScale, heightScale * 1.5, weightScale * 0.5);
     const bodyMaterial = new THREE.MeshPhongMaterial({ color: 0x00ff00 });
     const bodyMesh = new THREE.Mesh(bodyGeometry, bodyMaterial);
     bodyMesh.position.y = heightScale * 0.75; // 将身体向上移动一半高度
     group.add(bodyMesh);

     // Head
     const headGeometry = new THREE.BoxGeometry(weightScale * 0.5, weightScale * 0.5, weightScale * 0.5);
     const headMaterial = new THREE.MeshPhongMaterial({ color: 0xffcc00 });
     const headMesh = new THREE.Mesh(headGeometry, headMaterial);
     headMesh.position.y = heightScale * 1.5 + weightScale * 0.25; // 调整头部位置
     group.add(headMesh);

     // Arms
     const armGeometry = new THREE.BoxGeometry(weightScale * 0.25, heightScale * 0.6, weightScale * 0.25);
     const armMaterial = new THREE.MeshPhongMaterial({ color: 0x00ff00 });
     const leftArm = new THREE.Mesh(armGeometry, armMaterial);
     leftArm.position.set(-weightScale * 0.6, heightScale * 1.05, 0); // 调整手臂位置
     group.add(leftArm);
     const rightArm = new THREE.Mesh(armGeometry, armMaterial);
     rightArm.position.set(weightScale * 0.6, heightScale * 1.05, 0); // 调整手臂位置
     group.add(rightArm);

     // Legs
     const legGeometry = new THREE.BoxGeometry(weightScale * 0.25, heightScale * 0.8, weightScale * 0.25);
     const legMaterial = new THREE.MeshPhongMaterial({ color: 0x0000ff });
     const leftLeg = new THREE.Mesh(legGeometry, legMaterial);
     leftLeg.position.set(-weightScale * 0.25, heightScale * 0, 0); // 调整腿部位置
     group.add(leftLeg);
     const rightLeg = new THREE.Mesh(legGeometry, legMaterial);
     rightLeg.position.set(weightScale * 0.25, heightScale * 0, 0); // 调整腿部位置
     group.add(rightLeg);

     return group;
};

const createHumanoidsForUsers = () => {
     // 清除现有的人物模型
     humanoids.forEach(humanoid => scene.remove(humanoid));
     humanoids = [];

     // 为每个用户创建一个人物模型
     props.userData.forEach((user, index) => {
          const humanoid = createHumanoid(user);
          // 设置每个人物的位置，这里简单地将它们排成一行
          humanoid.position.x = (index - (props.userData.length - 1) / 2) * 3;
          // 不需要调整 y 位置，因为所有人物现在都站在 y=0 的平面上
          scene.add(humanoid);
          humanoids.push(humanoid);
     });
};

const init = () => {
     scene = new THREE.Scene();
     camera = new THREE.PerspectiveCamera(75, container.value.clientWidth / container.value.clientHeight, 0.1, 1000);

     renderer = new THREE.WebGLRenderer({ antialias: true });
     renderer.setSize(container.value.clientWidth, container.value.clientHeight);
     container.value.appendChild(renderer.domElement);

     controls = new OrbitControls(camera, renderer.domElement);
     controls.enableDamping = true;
     controls.dampingFactor = 0.25;
     controls.enableZoom = true;

     camera.position.z = 10;  // 增加相机距离以显示所有人物
     camera.position.set(0, 5, 15);  // 调整相机位置
     camera.lookAt(0, 2, 0);  // 让相机看向场景中心偏上的位置

     // Add some light
     const ambientLight = new THREE.AmbientLight(0x404040);
     scene.add(ambientLight);

     const directionalLight = new THREE.DirectionalLight(0xffffff, 0.5);
     directionalLight.position.set(1, 1, 1);
     scene.add(directionalLight);

     // 创建人物模型
     createHumanoidsForUsers();
};

const animate = () => {
     requestAnimationFrame(animate);

     humanoids.forEach(humanoid => {
          humanoid.rotation.y += 0.01;
     });

     controls.update();

     renderer.render(scene, camera);
};

const handleResize = () => {
     const width = container.value.clientWidth;
     const height = container.value.clientHeight;

     camera.aspect = width / height;
     camera.updateProjectionMatrix();

     renderer.setSize(width, height);
};

// 监听用户数据的变化
watch(() => props.userData, () => {
     createHumanoidsForUsers();
}, { deep: true });

onMounted(() => {
     init();
     animate();
     window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
     window.removeEventListener('resize', handleResize);
     if (renderer) {
          renderer.dispose();
     }
     if (controls) {
          controls.dispose();
     }
     if (scene) {
          scene.clear();
     }
});
</script>

<style scoped>
.visualization-container {
     width: 100%;
     height: 400px;
     /* 调整高度以适应您的布局 */
}
</style>